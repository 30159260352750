import { Component } from '@angular/core';
import { User } from './user';
// import {HttpClient } from '@angular/common/http';
import { SubmitService } from './submit.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // constructor(private http: HttpClient){}
  constructor(private _submitService: SubmitService){}
  topics = ['Angular','React','Vue'];
  arr = Array('Angular','React','Vue');
  arr1 =Array(
    {id:1,text:'Angular'},
    {id:2,text:'React'},
    {id:3,text:'Vue'});

  userModel = new User('Rob','rob@test.com', 2261231234,'0','morning',true);

  onSubmit(){
    // alert(JSON.stringify(this.userModel));
    this._submitService.sub(this.userModel)
    .subscribe(
      data => alert(data)
    )

  }
}
