import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class SubmitService {

  constructor(private _http: HttpClient) { }

  sub(user: User){
    return this._http.post<any>('./api/sub.php', user,{
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    });
  }
}
