  <div class="container-fluid mb-5">
      {{myForm.value | json}}
    <hr />
    {{userModel | json}}
    <form #myForm="ngForm" novalidate (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Name</label>
        <input type="text" required minlength="4" #name="ngModel" [class.is-invalid]="name.invalid" class="form-control is-invalid"  name="userName" [(ngModel)]="userModel.name">
        <small [class.d-none]="name.valid" class="text-danger">Name is required</small>
      </div>
      <div class="form-group">
        <label>Tel</label>
        <input type="tel" required #phone="ngModel" pattern="^\d{10}$" [class.is-invalid]="phone.invalid" class="form-control " name="tel" [(ngModel)]="userModel.tel">
        <small [class.d-none]="phone.valid" class="text-danger">Phone is required</small>
      </div>
      <div class="form-group">
        <label>Topic</label>
        <select required #topics="ngModel" [class.is-invalid]="topics.value ==0" name="topics" class="form-control" [(ngModel)]="userModel.topic">
          <option value="0" selected>select a topic</option>
          <option *ngFor="let topic of arr1" [value]="topic.id">{{topic.text}}</option>
        </select>
        <small [class.d-none]="topics.value >0" class="text-danger">Name is required</small>
        <!-- <small>{{topics.value}}</small> -->
      </div>
      <button [disabled]="myForm.form.invalid" class="btn btn-primary" type="submit">Submit</button> 
    </form>
    
    <div id="container">
      <div id="login" ng-app='angular_post_demo' ng-controller='sign_up'>
          <input type="text" size="40" ng-model="email"><br>
          <input type="password" size="40" ng-model="password"><br>
          <button ng-click="check_credentials()">Login</button><br>
          <span id="message"></span>
      </div>
    </div>
   
    
  </div>



